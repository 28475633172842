import React, { useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import { Document, Page, Text, StyleSheet, View, Image, PDFDownloadLink, PDFViewer, Font, Svg } from '@react-pdf/renderer';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf';
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf';
import { apiUrl } from '../../apiUrl/ApiUrl';
import Bill from '../bill/Bill';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const columns = [
    
    {
        field: 'toShipDate',
        headerName: '出貨日期',
        width: 150,
        editable: false,
    },
    {
        field: 'toShipNum',
        headerName: '出貨單號',
        width: 150,
        editable: false,
    },
    {
        field: 'orderNum',
        headerName: '訂單號碼',
        width: 150,
        editable: false,
    },
    {
        field: 'serialNum',
        headerName: '流水號',
        width: 80,
        editable: false,
    },
    {
        field: 'mechaine',
        headerName: '機種',
        width: 50,
        editable: false,
    },
    {
        field: 'drawNum',
        headerName: '圖號',
        width: 150,
        editable: false,
    },
    {
        field: 'itemName',
        headerName: '物品名',
        width: 80,
        editable: true,
    },
    {
        field: 'unit',
        headerName: '單位',
        width: 50,
        editable: false,
    },
    {
        field: 'realGive',
        headerName: '實交貨數',
        width: 100,
        type:'number',
        editable: false,
    },
    {
        field: 'orderPrice',
        headerName: '訂購單價',
        type:'number',
        width: 150,
        editable: true,
    },
    {
        field: 'amountReceivable',
        headerName: '應收金額',
        type:'number',
        width: 150,
        editable: false,
    },
    {
        field: 'company',
        headerName: '承製公司',
        width: 150,
        editable: false,
    },
    {
        field: 'client',
        headerName: '客戶',
        width: 150,
        editable: false,
    },
    { field: 'id', headerName: 'ID', width: 90 },
];

const columnsShip= [
    
    {
        field: '出貨日期',
        headerName: '出貨日期',
        width: 120,
        editable: false,
    },
    {
        field: '出貨單號',
        headerName: '出貨單號',
        width: 120,
        editable: false,
    },
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 110,
        editable: false,
    },
    {
        field: '流水號',
        headerName: '流水號',
        width: 80,
        editable: false,
    },
    {
        field: '圖號',
        headerName: '圖號',
        width: 110,
        editable: false,
    },
    {
        field: '物品名',
        headerName: '物品名',
        width: 110,
        editable: false,
    },
    {
        field: '單位',
        headerName: '單位',
        width: 60,
        editable: false,
    },
    {
        field: '實交貨數',
        headerName: '實交貨數',
        width: 110,
        editable: false,
    },
    {
        field: '訂購單價',
        headerName: '訂購單價',
        width: 110,
        editable: true,
    },
    {
        field: '應收金額',
        headerName: '應收金額',
        width: 110,
        editable: false,
        valueFormatter: (params) => {
            return Number(params.value).toLocaleString('zh-TW', { maximumFractionDigits: 0 });
        },
    },
    {
        field: '機種',
        headerName: '機種',
        width: 60,
        editable: false,
    },  
    {
        field: '承製公司',
        headerName: '承製公司',
        width: 110,
        editable: false,
    },
    {
        field: '客戶',
        headerName: '客戶',
        width: 110,
        editable: false,
    },
    { field: 'id', headerName: 'ID', width: 90 },
];

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() 返回的是 0-11，代表月份
    const day = date.getDate();

    // 使用模板字符串，并确保月份和日期为两位数
    return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
};

export default function ReceivableAccount() {
    const [rows, setRows] = React.useState([]);
    const [rows2, setRows2] = React.useState([]);
    const [makeCom, setMakeCom] = React.useState('34005573');
    const [client, setClient] = React.useState('台日');
    const [orderNum, setOrderNum] = React.useState('');
    const [drawNum, setDrawNum] = React.useState('');
    const [toshipNum, setToshipNum] = React.useState('');
    const [shipNum, setshipNum] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState({
        id: 753,
        label: null,
        manufacturer_Seq: "4500001",
        manufacturer_AbbreviationName: "台日",
        manufacturer_FullName: "台灣日立江森自控股份有限公司",
        manufacturer_UniformNum: "11915305",
        manufacturer_CityCode: "33810",
        manufacturer_FaxNum: "03-3245321",
        manufacturer_BossName: null,
        manufacturer_ContactName: null,
        manufacturer_CompanyAddress: "台北市南京東路三段63號",
        manufacturer_CompanyPhone: "03-3244711",
        manufacturer_CompanyFax: "03-3245321",
        manufacturer_ShipAddress: "桃園縣蘆竹鄉內厝村內溪路２９號",
        manufacturer_ShipAddress2: null,
        manufacturer_ShipAddress3: null,
        manufacturer_FactoryNum: null,
        manufacturer_FactoryFax: null,
        manufacturer_AccCode: "6116",
        manufacturer_PayMent: null
    });
    const [billNum, setBillNum] = React.useState('');
    const [billDate, setBillDate] = React.useState(formatDate(new Date()));
    const [orderWay, setOrderWay] = React.useState('num');

    const [billData, setBillData] = React.useState(null);
    const [print, setPrint] = React.useState(false);
    const [taxway, setTaxWay] = React.useState(3);
    const [tax, setTax] = React.useState(5);

    // 新增的狀態變量
    const [adjustDialogOpen, setAdjustDialogOpen] = useState(false); // 控制合計金額微調對話框
    const [newTotalAmount, setNewTotalAmount] = useState(''); // 用戶輸入的新合計金額

    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);

    const handleLockChange = async (event) => {
        // 更新billData狀態的lock屬性
        console.log(billData);
        try {
            const response = await axios.get(`${apiUrl}/ReceviableAccount/Lock?billNo=${billNum}`);
            if (response.status === 200) {
                setBillData((prevBillData) => ({
                    ...prevBillData,
                    lock: !response.data ? null : (response.data.lock ? 1 : null)
                }));         
            } else {
                alert('無該筆資料');
            }
        } catch (error) {
            console.error('Error fetching lock status:', error);
            alert('無該筆資料');
        }
    };

    const handleButtonClick = async () => {
        if(!billNum || !billDate){
            alert("請輸入發票號碼與發票日期")
        }else{
        try {
           
                fileInputRef.current.click();       
            
        } catch (error) {
            console.error('Error checking bill existence:', error);
            alert('查無該發票資訊');
        }
    }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        uploadShipExcel(file); // 假設這是上傳檔案的函數
        event.target.value = ''; // 清空上傳的檔案
    };

    const handleButtonClick2 = () => {
        fileInputRef2.current.click();
    };

    const fetchOptions = async () => {
        try {    
            const response = await axios.get(`${apiUrl}/Manufacturer/list`);
            if (response.status === 200) {
                setOptions(response.data.manufacturers);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFileChange2 = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        await checkUploadShipExcel(file); // 使用新的函數
        event.target.value = ''; // 清空上傳的文件
    };

    const checkUploadShipExcel = async (file) => {
        try {
            // 創建一個 FormData 對象來存儲文件數據
            const formData = new FormData();
            formData.append("excel", file);
        
            // 發送 POST 請求到後端
            const response = await axios.post(`${apiUrl}/ReceviableAccount/CheckShipExcel?no=${billNum}`, formData, {
                responseType: 'blob', // 重要：為了正確接收文件
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        
            // 檢查響應狀態並處理返回的文件
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '異常台日發票比對明細.xlsx'); // 設置下載的文件名
                document.body.appendChild(link);
                link.click();
            } else {
                alert('文件處理失敗');
            }
        } catch (error) {
            console.error('Failed to process file:', error);
            alert('文件處理失敗');
        }
    };

    const handleSearch = async () => {
        try {
            const response = await axios.get(`${apiUrl}/ReceviableAccount/GetMainsByFilter`, {
                params: {
                    makeCom,
                    client,
                    orderNum,
                    drawNum,
                    toshipNum
                }
            });
        
            if (response.data.statusCode === 200) {
                setRows(response.data.receviableAccounts);           
            } else {
                alert('查詢失敗');
            }
        } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失敗');
        }
    };

    const handleSearchBelow = async (toshipNo) => {
        setshipNum(toshipNo);
        try {
            const url = `${apiUrl}/ReceviableAccount/GetShipData`;
            const response = await axios.get(url, {
                params: {
                    billNum: billNum || '',
                    date: billDate || '',
                    way: orderWay || '',
                    toshipNo: toshipNo || ''
                }
            });
            if (response.status === 200) {
                setRows2(response.data);
                handleSearchBillData();
            } else {
                alert('查詢失敗');
            }
        } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失敗');
        }
    };

    const SaveShipData = async () => {

        try {
            const additionalData = rows2.map(row => ({
                Id: row.id,
                realGive: parseFloat(row.實交貨數) || 0, // 確保值為數字
                orderPrice: parseFloat(row.訂購單價) || 0, // 確保值為數字
                amountReceivable: parseFloat(row.應收金額) || 0, // 確保值為數字
            }));
            console.log(additionalData);
            const response = await axios.post(`${apiUrl}/ReceviableAccount/SaveShipData`, additionalData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.status === 200) {
                setRows2(response.data); // 將後端返回的資料寫入 rows2
                alert('儲存成功');
            } else {
                alert('儲存失敗');
            }
        } catch (error) {
            console.error('Failed to save data:', error);
            alert('儲存失敗');
        }
    };
    

    const UpdateBelow = async () => {
        try {
            const response = await axios.get(`${apiUrl}/ReceviableAccount/UpdateShipData`, {
                params: { billNum }
            });
        
            if (response.status === 200) {
                await handleSearchBelow(null);
                alert('刪除成功');
            } else {
                alert('刪除失敗');
            }
        } catch (error) {
            console.error('Failed to add data:', error);
            alert('刪除失敗');
        }
    };

    const handleSearchBillData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/ReceviableAccount/GetBillData`, {
                params: { billNum }
            });
            if (response.status === 200 || response.status === 204) {
                setBillData(response.data);
            } else {
                alert('查詢失敗');
            }
        } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失敗');
        }
    };

    const handleClickOpenList = async () => {
        try {
            const response = await axios.get(`${apiUrl}/ReceviableAccount/GetBillDataList`, {
                params: {
                    billNum: billNum || '',
                    date: billDate || '',
                    way: orderWay || '',
                },
                responseType: 'blob' // 設置 responseType 為 blob
            });
        
            if (response.status === 200 || response.status === 204) {
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl);
            } else {
                alert('查詢失敗');
            }
        } catch (error) {
            console.error('Failed to fetch PDF:', error);
            alert('查詢失敗');
        }
    };

    const handleRecaculateBillData = async () => {
        try {
            const dataToSend = {
                data: rows2, 
                way: taxway,
                num: tax,
                billNum,
                date: billDate
            };
        
            // 發起 POST 請求
            const response = await axios.post(`${apiUrl}/ReceviableAccount/NewRecaculate`, dataToSend);
        
            if (response.status === 200) {
                setBillData(response.data);
            } else {
                alert('重新計算失敗');
            }
        } catch (error) {
            console.error('Failed to recalculate bill data:', error);
            alert('重新計算失敗');
        }
    };

    const uploadShipExcel = async (file) => {
        try {
            // 創建一個 FormData 對象來存儲文件數據
            const formData = new FormData();
            formData.append("excel", file);
        
            // 發送 POST 請求到後端，附加 query string 參數
            const response = await axios.post(`${apiUrl}/ReceviableAccount/ShipExcel`, formData, {
                params: { billDate },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        
            // 檢查響應狀態
            if (response.status === 200) {
                alert('文件上傳成功');
                handleSearchBelow(null);
            } else {
                alert('文件上傳失敗');
            }
        } catch (error) {
            console.error('Failed to upload file:', error);
            alert('文件上傳失敗');
        }
    };

    const handleClickOpen = async () => {
        await handleSearchBelow(); // 等待 handleSearchBelow 完成
        setPrint(true);
    };

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
        if(newValue === null || newValue === undefined) return;
        setClient(newValue.manufacturer_AbbreviationName); 
    };

    const handleRowDoubleClick = async (params) => {
        if(billData && billData.lock != null) {
            alert('已上鎖 不得動彈');
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/ReceviableAccount/DoubleClickBill`, {
                Id: params.row.id,
                Date: billDate,
                BillNo: billNum
            });
            if(response.status === 200 || response.status === 204) {
                await handleSearch();
                await handleSearchBelow(params.row.toShipNum);
            }
        } catch (error) {
            console.error('請求錯誤', error);
        }
    };

    const handleClose = () => {
        setPrint(false);
    };

    React.useEffect(() => {
        fetchOptions();
    }, []); 

    React.useEffect(() => {
        if (billData && billData.date) {
            setBillDate(billData.date);
        }
    }, [billData]); 

    React.useEffect(() => {
        if (rows2.length === 0 ) {
            setBillData(billData => ({
                ...billData,
                稅額: "",
                金額: "",
                總計: ""  // 修改屬性為新值
            }));
        }
    }, [rows2]);

    const handleAdjustAmount = () => {
        setAdjustDialogOpen(true); // 打開對話框
        setNewTotalAmount('0'); // 初始化輸入框的值為當前合計金額
    };

    const handleExportExcel = async () => {
      
        try 
      {
        
        const response = await axios.post(
            `${apiUrl}/ReceviableAccount/BillExcel?billNo=${billNum}`,
            null, // 如果需要傳送資料，請在這裡傳遞
            {
                responseType: 'blob', // 設置回應類型為 blob
            }
        );
        if (response.status === 200 && response.data) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'BillData.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            alert('Excel 文件已成功下載。');
        } else {
            alert('生成 Excel 文件失敗。');
        }
    } catch (error) {
        console.error('生成 Excel 文件失敗:', error);
        alert('生成 Excel 文件時發生錯誤。');
    }
    };

    const processRowUpdate = useCallback((newRow, oldRow) => {
        // 根據訂購單價和實交貨數計算應收金額
        const updatedRow = { 
            ...oldRow, 
            ...newRow, 
            應收金額: !Number.isNaN(newRow['訂購單價']) && !Number.isNaN(newRow['實交貨數'])
                ? newRow['訂購單價'] * newRow['實交貨數']
                : oldRow['應收金額'],
        };
    
        // 更新 rows2 中的對應數據
        setRows2((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? updatedRow : row))
        );
    
        // 返回更新後的行
        return updatedRow;
    }, []);
    
    const handleSubmitAdjustAmount = async () => {
        if (!newTotalAmount || Number.isNaN(newTotalAmount)) {
            alert('請輸入有效的金額');
            return;
        }

        try {
            // 假設您需要將新的合計金額發送到後端進行更新
            const response = await axios.post(`${apiUrl}/ReceviableAccount/UpdateTotalAmount`, {
              billNum,
              newTotalAmount: Number(newTotalAmount),
            });
              if (response.status === 200) {
                  alert('合計金額更新成功');
                  setAdjustDialogOpen(false); // 關閉對話框
                  // setRows2([]);
                  handleSearchBelow(); // 重新查詢以更新界面
              } else {
                  alert('合計金額更新失敗');
              }
          
            } catch (error) {
                console.error('更新合計金額失敗:', error);
                alert('更新合計金額失敗');
            }
    };

    return (
        <>
            <Box sx={{ height: 400, width: '100%', margin: '10px' }}>
                <FormControl sx={{ margin: '10px' }}>
                    <InputLabel id="demo-simple-select-label">承製公司</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={makeCom}
                        label="承製公司"
                        onChange={(e) => setMakeCom(e.target.value)}
                    >
                        <MenuItem value={'34005573'}>34005573</MenuItem>
                        <MenuItem value={'23032911'}>23032911</MenuItem>
                        <MenuItem value={'86841262'}>86841262</MenuItem>
                        <MenuItem value={'200001'}>200001</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ margin: '10px' }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        sx={{ width: '100%' }}
                        value={selectedOption}
                        onChange={handleChange}
                        getOptionLabel={(option) => option.manufacturer_AbbreviationName}
                        // 設置renderInput來渲染輸入元件
                        renderInput={(params) => <TextField {...params} label="客戶" />}
                    />
                </FormControl>
                <TextField sx={{ margin: '10px' }} id="outlined-basic" label="訂單號碼" variant="outlined" onChange={(e)=>setOrderNum(e.target.value)}/>
                <TextField sx={{ margin: '10px' }} id="outlined-basic" label="圖號" variant="outlined" onChange={(e)=>setDrawNum(e.target.value)}/>
                <TextField sx={{ margin: '10px' }} id="outlined-basic" label="出貨單號" variant="outlined" onChange={(e)=>setToshipNum(e.target.value)}/>
                <Button sx={{ margin: '10px', marginTop: '20px' }} variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                    搜尋
                </Button>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    onRowDoubleClick={handleRowDoubleClick}
                    pageSizeOptions={[20,30,40]}
                    disableRowSelectionOnClick
                />
            </Box>
            <Box sx={{ height: 400, width: '90%', margin: '10px', marginTop: '10%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField id="outlined-basic" label="發票號碼" variant="outlined" value={billNum} onChange={(e)=>setBillNum(e.target.value)}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField id="outlined-basic" label="發票日期" variant="outlined"           
                            value={billDate}
                            onChange={(e)=>setBillDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={orderWay}
                                onChange={(e)=>setOrderWay(e.target.value)}
                            >
                                <FormControlLabel value="num" control={<Radio />} label="按圖號+批號 排" />
                                <FormControlLabel value="date" control={<Radio />} label="按出貨日期 排" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" onClick={()=>handleSearchBelow(null)}>搜尋</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <Button variant="contained" onClick={handleButtonClick} disabled={billData && billData.lock != null}>
                            匯入台日發票2015
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileInputRef2}
                            onChange={handleFileChange2}
                        />
                        <Button variant="contained" onClick={handleButtonClick2} disabled={billData && billData.lock != null}>
                            台日發票比對2015
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" onClick={UpdateBelow} disabled={billData && billData.lock != null}>刪除發票</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">小數點</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={taxway}
                                onChange={(e)=>setTaxWay(e.target.value)}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="無條件進位" />
                                <FormControlLabel value={3} control={<Radio />} label="四捨五入" />
                                <FormControlLabel value={2} control={<Radio />} label="無條件捨去" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField id="outlined-basic" label="稅率" variant="outlined" value={tax} onChange={(e)=>setTax(e.target.value)}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleRecaculateBillData} disabled={billData && billData.lock != null}>計算</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            id="outlined-basic" 
                            label="合計" 
                            variant="outlined" 
                            value={billData ? billData.金額 : ''} 
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                        {/* 使用 Box 來佈局按鈕 */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleAdjustAmount}
                                fullWidth
                                sx={{ mr: 1 }}
                            >
                                合計金額微調
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={handleExportExcel}
                                fullWidth
                            >
                                匯出EXCEL
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            id="outlined-basic" 
                            label="營業稅" 
                            variant="outlined" 
                            value={billData ? billData.稅額 : ''} 
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            id="outlined-basic" 
                            label="總計" 
                            variant="outlined" 
                            value={billData ? billData.總計 : ''} 
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={billData && billData.lock != null} 
                                        onChange={handleLockChange}
                                    />
                                } 
                                label="已結案" 
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleClickOpenList}>對帳單</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={SaveShipData} 
                            disabled={billData && billData.lock != null} // 根據 lock 狀態禁用按鈕
                        >
                        儲存變更
                    </Button>
                </Grid>
                </Grid>
                <DataGrid
                    rows={rows2}
                    columns={columnsShip}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                        },
                    }}
                    onRowDoubleClick={handleRowDoubleClick}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate} // 使用 processRowUpdate
                    experimentalFeatures={{ newEditingApi: true }} // 啟用新編輯 API
                    onProcessRowUpdateError={(error) => {
                        console.error(error);
                        alert('更新失敗，請稍後再試。');
                    }}
                />
            </Box>

            {/* 新增的合計金額微調 Dialog 組件 */}
            <Dialog open={adjustDialogOpen} onClose={() => setAdjustDialogOpen(false)}>
                <DialogTitle>合計金額微調</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        請輸入調整金額。
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="new-total-amount"
                        label="調整金額"
                        type="number" // 確保類型為 number
                        fullWidth
                        variant="standard"
                        value={newTotalAmount}
                        onChange={(e) => setNewTotalAmount(e.target.value)}
                        
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAdjustDialogOpen(false)}>取消</Button>
                    <Button onClick={handleSubmitAdjustAmount}>送出</Button>
                </DialogActions>
            </Dialog>

            {/* 列印預覽 Dialog */}
            <Dialog
                fullScreen
                open={print}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            列印預覽
                        </Typography>
                    </Toolbar>
                </AppBar>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument data={rows2} billNum={billNum} billDate={billData ? (billData.date ? billData.date : '') : ''}/>
                </PDFViewer>
            </Dialog> 
        </>
    );
}

Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont, // 使用您的實際路徑
});
Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: chLight, // 使用您的實際路徑
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingBottom: 30, // 設置底部邊距，數值可根據需要調整
        // ... 其他樣式
    },
    gridContainer: {
        flexDirection: 'row',
        margin: 10,
        alignItems: 'center',
    },
    gridTitle: {
        flexGrow: 0,
        fontFamily: 'TaipeiSansTCBeta-Bold',
        alignItems: 'center',
    },
    gridItem1: {
        flexGrow: 1,
        padding: 3,
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    gridItem2: {
        flexGrow: 1,
        padding: 3,
        fontFamily: 'TaipeiSansTCBeta-Light',
    },
    gridImg: {
        flexGrow: 1,
        paddingLeft: 100,
        paddingRight: 100,
        height:50,
        marginBottom:10
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        alignItems: 'center',
        justifyContent: 'space-around', // 平均分配空間
        marginBottom: 10, // 與下個元素的間距
    },
    headerItem: {
        flexGrow: 1,
        textAlign: 'center',
        padding: 5, // 內間距
        fontSize: 10,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        alignItems: 'center',
    },
    cell: {
        textAlign: 'center',
        padding: 5,
        fontSize: 10,
    },
});

const MyDocument = ({ data, billNum, billDate }) => {
    const today = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
    };

    return (
        <> 
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.gridTitle}>
                            <Text>福蒙精密工業股份有限公司-應收帳款對帳單</Text>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>發票號碼:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{billNum}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>列印日期:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{formatDate(today)}</Text>
                            </View>
                        </View> 
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>發票日期:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{billDate}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>頁次:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>1/1</Text>
                            </View>
                        </View>
                        <View style={styles.headerRow}>
                            <View style={styles.headerItem}>
                                <Text>出貨日期</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>出貨單號</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>訂單號碼</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>流水號</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>圖號</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>物品名</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>單位</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>實交貨數</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>訂購單價</Text>
                            </View>
                            <View style={styles.headerItem}>
                                <Text>應收金額</Text>
                            </View>
                        </View>
                        {data.map((item, index) => (
                            <View style={styles.row} key={index}>
                                <Text style={styles.cell}>{item.出貨日期}</Text>
                                <Text style={styles.cell}>{item.出貨單號}</Text>
                                <Text style={styles.cell}>{item.訂單號碼}</Text>
                                <Text style={styles.cell}>{item.流水號}</Text>
                                <Text style={styles.cell}>{item.圖號}</Text>
                                <Text style={styles.cell}>{item.物品名}</Text>
                                <Text style={styles.cell}>{item.單位}</Text>
                                <Text style={styles.cell}>{item.實交貨數}</Text>
                                <Text style={styles.cell}>{item.訂購單價}</Text>
                                <Text style={styles.cell}>{item.應收金額}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </>
    );
};
