import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Grid from '@mui/material/Grid';
import BomMainDataGrid from './BomMainDataGrid';
import BomActions from './BomActions';
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function Bom({ data }) {
  const [rows, setRows] = useState([]);
  const [newData, setNewData] = useState(null);
  const [inputValue, setInputValue] = useState("58085400");
  const [mainBom, setMainBom] = useState({
    DrawNum: '',
    ItemName: '',
    BuildDate: '',
    PreDays: 0
  });
  const [detailBom, setDetailBom] = useState({
    Order: '',
    DetailDrawNum: '',
    ItemName: '',
    GiveAmount: 0,
    Chk: ''
  });
  const [detailBoms, setDetailBoms] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [detailOptions, setDetailsOptions] = useState([]);
  const [item, setItem] = useState('');
  const [addBillRows, setAddBillRows] = useState([]);
  const [selectedMainDrawNum, setSelectedMainDrawNum] = useState(null); // 保存主件圖號

  const [detailedData, setDetailedData] = useState(null); // 保存詳細資料

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'orderBy',
      headerName: '序',
      width: 200,
      editable: true,
    },
    {
      field: 'detailDrawNum',
      headerName: '子件圖號',
      width: 200,
      editable: true,
    },
    {
      field: 'itemName',
      headerName: '物品名',
      width: 200,
      editable: true,
    },
    {
      field: 'chk',
      headerName: 'CHK',
      width: 120,
      editable: true,
    },
    {
      field: 'dispenseAmount',
      headerName: '配賦量',
      type: 'number',
      sortable: false,
      width: 100,
    },
    {
      field: 'actions',
      headerName: '操作',
      width: 150,
      type: 'actions',
      renderCell: (params) => <BomActions params={params} setRows={setRows} />
    }
  ];

  const detailColumns = [
    { field: 'id', headerName: 'ID', width: 40 },
    {
      field: 'Order',
      headerName: '序',
      width: 60,
      editable: true,
    },
    {
      field: 'detailDrawNum',
      headerName: '子件圖號',
      width: 80,
      editable: true,
    },
    {
      field: 'ItemName',
      headerName: '物品名',
      width: 160,
      editable: true,
    },
    {
      field: 'Chk',
      headerName: 'CHK',
      width: 40,
      editable: true,
    },
    {
      field: 'GiveAmount',
      headerName: '配賦量',
      type: 'number', // 修正類型為 'number'
      sortable: false,
      width: 100,
    },
    {
      field: 'actions',
      headerName: '操作',
      width: 40,
      type: 'actions',
      renderCell: (params) =>
        <Button
          variant="outlined"
          endIcon={<DeleteForeverIcon />}
          onClick={() => handleClear(params.row.DetailDrawNum)}
        />
    }
  ];

  useEffect(() => {
    if (data && data.boms) {
      setRows(data.boms);
    }
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = (DetailDrawNum) => {
    const newArray = detailBoms.filter(item => item.DetailDrawNum !== DetailDrawNum);
    setDetailBoms(newArray);
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(`${apiUrl}/Bom/EditDetails`, editedRows);
      if (response.data.statusCode === 200) {
        setRows(response.data.boms);
        setNewData(response.data);
        alert('修改成功');
      } else {
        alert('修改失敗');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      alert('修改失敗');
    }
  };

  const processRowUpdate = (newRow, oldRow) => {
    const index = editedRows.findIndex(row => row.id === newRow.id);
    if (index > -1) {
      editedRows.splice(index, 1);
    }
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${apiUrl}/Bom/GetMain`, {
        params: { drawNum: inputValue.trim() }
      });

      if (response.data.bom != null) {
        console.log(response.data.bom);
        setRows(response.data.boms);
        setNewData(response.data);
        alert('搜尋成功');
      } else {
        alert('搜尋失敗');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      alert('搜尋失敗');
    }
  };

  const handleInputChange = (event, propertyName) => {
    const value = event.target ? event.target.value : event;
    setMainBom((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  const handleInsertDetail = () => {
    const newDetail = {
      ...detailBom,
      id: uuidv4(), // 使用 uuid 生成唯一 ID
    };
    setDetailBoms((prevItems) => [...prevItems, newDetail]);
  };

  const handleDetailInputChange = (event, propertyName) => {
    const value = event.target ? event.target.value : event;
    setDetailBom((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  const handleDetailsOptionsClick = async (no) => {
    try {
      const response = await axios.get(`${apiUrl}/PurchasePrice/ItemOptions`, {
        params: { num: no, vendor: '' },
      });
      if (response.status === 200) {
        setDetailsOptions(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMainDrawNumSelect = async (mainDrawNum) => {
    setSelectedMainDrawNum(mainDrawNum);
    console.log("選中的主件圖號: ", mainDrawNum);
    
    try {
      const response = await axios.get(`${apiUrl}/Bom/GetMainDetails`, {
        params: { drawNum: mainDrawNum },
      });
      if (response.data.boms != null) {
        console.log(response.data.bom);
        setRows(response.data.boms);
        alert('搜尋成功');
      } else {
        alert('搜尋失敗');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      alert('搜尋失敗');
    }
  };

  const handleRowDoubleClick = async (params) => {
    console.log('雙擊事件觸發:', params); // 調試輸出
    const drawNum = params.row.detailDrawNum;
    setSelectedMainDrawNum(drawNum); 

    try {
      const response = await axios.get(`${apiUrl}/Bom/GetMainDetails`, {
        params: { drawNum },
      });

      if (response.status === 200 && response.data) {
        setRows(response.data.boms);
        alert('成功取得詳細資料');
      } else {
        alert('未找到對應的詳細資料。');
      }
    } catch (error) {
      console.error('資料抓取失敗:', error);
      alert('抓取詳細資料時發生錯誤。');
    }
  };

  const handleDetailChange = (event) => {
    const selectedItem = event.target.value;
    setItem(selectedItem);
    setAddBillRows(prevAddBillRows => [...prevAddBillRows, selectedItem]);
    setDetailBom(prevDetailBom => ({
      ...prevDetailBom,
      DetailDrawNum: selectedItem.圖號,
      ItemName: selectedItem.物品名
    }));
  };

  const handleSubmit = async () => {   
    try {
      const request = {
        Bom: mainBom,
        BomDetailModels: detailBoms
      };
      const response = await axios.post(`${apiUrl}/Bom/NewBom`, request);
      if (response.status === 200) {
        setRows(response.data.boms);
        setNewData(response.data);
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert('失敗 欄位有誤');
    } 
  };

  return (
    <>
      <Box display="flex" alignItems="center" margin="20px 0" sx={{ width: '80%', margin: 'auto', marginTop: '40px', marginBottom: '20px' }}>
        <TextField
          required
          id="outlined-required"
          label="圖號"
          defaultValue="58085400"
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Stack direction="row" spacing={2} sx={{ marginLeft: '40px' }}>
          <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
            搜尋
          </Button>
          <Button variant="contained" endIcon={<AddIcon />} onClick={handleClickOpen}>
            新增
          </Button>
        </Stack>
      </Box>
      <BomMainDataGrid data={data} newData={newData} onMainDrawNumSelect={handleMainDrawNumSelect} />
      <Box>
        <h3>選中的主件圖號：{selectedMainDrawNum}</h3> {/* 顯示選中的主件圖號 */}
      </Box>
      <Box sx={{ height: 600, width: '80%', margin: 'auto', marginTop: '30px', marginBottom: '8%' }}>
        <Tooltip title="Add" arrow sx={{ marginTop: '30px' }}>
          <Button endIcon={<SaveIcon />} type="submit" onClick={handleSave}>儲存</Button>
        </Tooltip>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          editMode="row"
          pageSizeOptions={[10, 20]}
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => alert(error)}
          onRowEditCommit={(rowId, e) => {
            handleSave(rowId, e);
          }}
          onRowDoubleClick={handleRowDoubleClick} // 綁定雙擊事件到主畫面 DataGrid
        />
      </Box>

      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>新增Bom</DialogTitle>
        <DialogContent >
          <Grid container spacing={2}>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                id="standard-search"
                label="主件圖號"
                type="search"
                variant="standard"
                value={mainBom.DrawNum}
                onChange={(e) => handleInputChange(e, 'DrawNum')}
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                id="standard-search"
                label="物品名"
                type="search"
                variant="standard"
                value={mainBom.ItemName}
                onChange={(e) => handleInputChange(e, 'ItemName')}
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                id="standard-search"
                label="建檔日期"
                type="search"
                variant="standard"
                value={mainBom.BuildDate}
                onChange={(e) => handleInputChange(e, 'BuildDate')}
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                id="standard-search"
                label="提前天數"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, 'PreDays')} // 修正為 'PreDays'
                value={mainBom.PreDays}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" endIcon={<AddIcon />} onClick={handleInsertDetail}>
                新增BOM細項
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="standard-search"
                    label="序"
                    type="search"
                    variant="standard"
                    value={detailBom.Order}
                    onChange={(e) => handleDetailInputChange(e, 'Order')}
                  />
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="standard-search"
                    label="子件圖號"
                    type="search"
                    variant="standard"
                    value={detailBom.DetailDrawNum}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleDetailsOptionsClick(detailBom.DetailDrawNum);
                        e.preventDefault(); // 防止表單提交
                      }
                    }}
                    onChange={(e) => handleDetailInputChange(e, 'DetailDrawNum')}
                  />
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="standard-search"
                    label="物品名"
                    type="search"
                    variant="standard"
                    value={detailBom.ItemName}
                    onChange={(e) => handleDetailInputChange(e, 'ItemName')}
                  />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="standard-search"
                    label="配賦量"
                    type="search"
                    variant="standard"
                    onChange={(e) => handleDetailInputChange(e, 'GiveAmount')}
                    value={detailBom.GiveAmount}
                  />
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    id="standard-search"
                    label="CHK"
                    type="search"
                    variant="standard"
                    onChange={(e) => handleDetailInputChange(e, 'Chk')}
                    value={detailBom.Chk}
                  />
                </Grid>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">料品選擇</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item}
                    label="料品選擇"
                    onChange={handleDetailChange}
                  >
                    {detailOptions.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        圖號:{item.圖號} 物品名:{item.物品名} 單位:{item.單位} 單價:{item.單價}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: '300px' }}>
              <DataGrid
                rows={detailBoms}
                columns={detailColumns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20]}
                onRowDoubleClick={handleRowDoubleClick} // 綁定雙擊事件
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleSubmit}>送出</Button>
        </DialogActions>
      </Dialog>

      {/* 詳細資料顯示區域 */}
      <Box sx={{ width: '80%', margin: 'auto', marginTop: '20px' }}>
        {detailedData ? (
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <h3>詳細資料</h3>
            <Grid container spacing={2}>
              {/* 根據 response.data 的結構自訂顯示內容 */}
              <Grid item xs={6}>
                <strong>圖號:</strong> {detailedData.DetailDrawNum}
              </Grid>
              <Grid item xs={6}>
                <strong>物品名:</strong> {detailedData.ItemName}
              </Grid>
              {/* 根據您的資料結構添加更多欄位 */}
            </Grid>
          </Paper>
        ) : (
          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <em>雙擊一行以查看詳細資料。</em>
          </Box>
        )}
      </Box>
    </>
  );
};
