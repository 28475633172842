// src/components/erp/inspection/PrintQuantityCell.js

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const PrintQuantityCell = ({ id, value, handlePrintQuantityChange }) => {
    const [inputValue, setInputValue] = useState(value || 2);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const parsedValue = parseInt(inputValue, 10);
            if (!Number.isNaN(parsedValue)) {
                handlePrintQuantityChange(id, parsedValue);
            }
        }
    };

    return (
        <TextField
            type="number"
            size="small"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            inputProps={{ min: 0 }}
        />
    );
};

export default PrintQuantityCell;