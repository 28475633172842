import React, { useEffect, useState, useCallback } from 'react';

import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg } from '@react-pdf/renderer';
import { useGridApiRef,DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import PrintQuantityCell from './PrintQuantityCell'; // 引入新組件
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf'
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf'
import { apiUrl } from '../../apiUrl/ApiUrl';




const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


export default function Inspection({data}) {

    const [rows,setRows] = React.useState([]);
    const [print,setPrint] = React.useState(false);
    const [checkPrint,setCheckPrint] = React.useState(false);
    const [expectedRows, setExpectedRows] = useState([]);
    const [filter,setFilter] = React.useState('');
    const [filterValue,setFilterValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [sendRow,setSendRow] = React.useState(null);
    const [orderNum,setOrderNum] = React.useState('');
    const [maxAmount,setMaxAmount] = React.useState(0);
    const [printItem,setPrintItem] = React.useState(null);
    const [printItems,setPrintItems] = React.useState([]);
    const [baseOrder64,setBaseOrder64] = React.useState('');
    const [basePinum64,setBasePinum64] = React.useState('');
    const [piNumCounts, setPinumCounts] = React.useState([]);
    const [dataCleared, setDataCleared] = React.useState(false);
    const [barCode, setBarCode] = useState('');
    
    const countPiNUms = (items) => {
        // 使用 reduce 函數來計算每個 ID 的出現次數
        const flatItems = items.flat();

        // 計算每個 id 的出現次數
        const countBypiNum = flatItems.reduce((acc, item) => {
            acc[item.piNum] = (acc[item.piNum] || 0) + 1;
            return acc;
        }, {});
        
        // 將物件轉換成陣列形式
        const resultArray = Object.entries(countBypiNum).map(([piNum, count]) => ({
            piNum, // 將鍵值轉換回數字類型，因為物件的鍵在 JavaScript 中是字串形式
            count
        }));
        
        return resultArray;
    }

    const generateBarcodes = (value, setValue) => {
    const canvas = document.createElement('canvas');
    try {
        JsBarcode(canvas, value, {
            format: 'CODE128',
            displayValue: false,
        });
        setValue(canvas.toDataURL('image/png'));
    } catch (error) {
        console.error("Barcode generation failed:", error);
    }
};


    const handleDeleteReOrder = async(row) => {
        try {
            const response= await axios.post(`${apiUrl}/Inspection/DeleteReserveOrder`, row, {
                params: {
                    UserName: sessionStorage.getItem('userName')
                }
            });
        
            if (response.data.statusCode === 200) {
              alert('成功');
              setExpectedRows(response.data.reservationOrders)
              setRows(response.data.orders)
              // 可以在这里添加其他成功的处理逻辑
              const updatedPrintItems = printItems.map(subArray =>
                subArray.filter(item => item.id !== row.id)
            );

            

            // 其他逻辑
            setExpectedRows(response.data.reservationOrders);
            setRows(response.data.orders);

            } else {
              alert('失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('失败');
          }
    };

    const SaveAll = async () => {
        try {

            const rowsWithoutPrintQuantity = expectedRows.map(row => {
                const { printQuantity,itemIndex, ...rest } = row; // 使用解構賦值來排除 PrintQuantity
                return rest; // 回傳不包含 PrintQuantity 的新物件
            });
            console.log(rowsWithoutPrintQuantity);
            // 发送批量删除请求到服务器，传递 expectedrows 内的所有行数据
            const response = await axios.post(
                `${apiUrl}/Inspection/DeleteAllReserveOrder`,
                rowsWithoutPrintQuantity, // 主體資料
                {
                    params: {
                        UserName: sessionStorage.getItem('userName'), // 查詢參數
                    },
                }
            );
            if (response.data.statusCode === 200) {
                // 如果服务器删除成功，则清空本地状态
                setExpectedRows([]);  // 清空 expectedrows
                setRows([]);          // 清空 rows 或者 data，取决于你显示的是什么
                setDataCleared(true); // 标记数据已经清空
                alert('資料已清除');
            } else {
                alert('刪除失敗');
            }
        } catch (error) {
            console.error('Failed to delete all data:', error);
            alert('刪除失敗');
        }
    };
    

    const handleSelectAll =async() => {
        let url = '';
        switch (filter) {
            case '批號':
              url = `${apiUrl}/Inspection/GetExpecteds?piNum=${filterValue}&UserName=${sessionStorage.getItem('userName')}`;
              break;
            case '圖號':
                url =  `${apiUrl}/Inspection/GetExpecteds?drawNum=${filterValue}&UserName=${sessionStorage.getItem('userName')}`;
              break;
            
            default:
                alert('使用此功能需要選擇批號或圖號');   
            }  

        try {
            const response= await axios.post(url);
            if (response.status === 200) {
                const updatedReservationOrders = response.data.reservationOrders.map((order,index) => ({
                    ...order, // 保留订单对象的其他属性
                    printQuantity: 2, // 设置 printQuantity 的默认值，可以根据需求调整
                    itemIndex: index + 1,
                    }));
                // 将更新后的数组设置给 setExpectedRows
                setExpectedRows(updatedReservationOrders);
                // serchOrder();  // 更新页面数据
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };
    
    const handlePrintClickOpen = (row) => {   
        console.log(printItems); 
        setPrint(true);
    };
    
      const handleClose = () => {
        setPrint(false);
        
    };
    
    const handleDialogOpen = (params, event, details) => {
        // 设置 sendRow 为当前选中的行
        setSendRow(params.row);
    
        // 设置 maxAmount 为选中行的 '未印' 字段值
        setMaxAmount(params.row.未印);
        
        // 可选：设置订单号（如果需要在对话框中显示）
        setOrderNum(params.row.orderNum);
        
        // 打开对话框
        setOpen(true);
        
        // 将选中的行添加到 expectedRows（根据您的业务逻辑）
        // setExpectedRows(prevRows => [...prevRows, params.row]);
        
        // 从 rows 中移除该行
        setRows(prevRows => prevRows.filter(row => row.id !== params.row.id));
    };
    


    const sendOrder = async (row) => {
        // const data = row || sendRow;  // 使用传入的 row 或者 sendRow
        if (!sendRow) {
            alert('沒有選中的訂單');
            return;
        }
    
        // 将 maxAmount 和 未印 转换为数字，以确保比较正确
        const maxAmountNum = Number(maxAmount);
        const 未印Num = Number(sendRow.未印);
    
        // 检查 maxAmount 是否不超过 未印
        if (Number.isNaN(maxAmountNum) || Number.isNaN(未印Num)) {
            alert('數量格式不正確');
            return;
        }
    
        if (maxAmountNum > 未印Num) {
            alert('最大数量不能超过未印的数量');
            return; // 终止函数，不进行 API 调用
        }
    
        // 设置 expectedAmount 为 maxAmount
        const dataToSend = {
            ...sendRow,
            expectedAmount: maxAmountNum, // 使用 maxAmount 作为预交货量
        };
    

        try {
            const response = await axios.post(`${apiUrl}/Inspection/ReserveOrders`, dataToSend, {
                params: {
                    UserName: sessionStorage.getItem('userName')
                }
            });
            if (response.data.statusCode === 200) {
                console.log(response.data);
                // 使用 map 遍历 reservationOrders 并添加 printQuantity 字段
                const updatedReservationOrders = response.data.reservationOrders.map((order,index) => ({
                    ...order, // 保留订单对象的其他属性
                    printQuantity: 2, // 设置 printQuantity 的默认值，可以根据需求调整
                    itemIndex: index + 1,
                    }));
                // 将更新后的数组设置给 setExpectedRows
                setExpectedRows(prevRows => [
                    ...prevRows, // 保留原有的資料
                    ...updatedReservationOrders // 添加新的資料
                ]);
                // serchOrder();  // 更新页面数据
            } else {
                alert('添加失败');
            }
            setOpen(false);
        } catch (error) {
            console.error('Failed to add data:', error);
            alert('添加失败');
        }
    };

    const serchOrder =async() => {
        let url = '';
        switch (filter) {
            case '批號':
              url = `${apiUrl}/Inspection/GetNewOrders?piNum=${filterValue}`;
              break;
            case '圖號':
                url =  `${apiUrl}/Inspection/GetNewOrders?drawNum=${filterValue}`;
              break;
            case '訂單號碼':
                url =  `${apiUrl}/Inspection/GetNewOrders?orderNum=${filterValue}`;
              break;
            default:
                url =  `${apiUrl}/Inspection/GetNewOrders`;   
            }  

        try {
            const response= await axios.get(url);
            if (response.status === 200) {
              setRows(response.data)
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };

    const getoldorder =async() => {
          
            try {
                const response = await axios.get(`${apiUrl}/Inspection/ReserveOldOrders?UserName=${sessionStorage.getItem('userName')}&OrderNum=${barCode}`);
                if (response.data.statusCode === 200) {
                    console.log(response.data);
                    // 使用 map 遍历 reservationOrders 并添加 printQuantity 字段
                    const updatedReservationOrders = response.data.reservationOrders.map((order,index) => ({
                        ...order, // 保留订单对象的其他属性
                        printQuantity: 2, // 设置 printQuantity 的默认值，可以根据需求调整
                        itemIndex: index + 1,
                        }));
                    // 将更新后的数组设置给 setExpectedRows
                    setExpectedRows(prevRows => [
                        ...prevRows, // 保留原有的資料
                        ...updatedReservationOrders // 添加新的資料
                    ]);
                    // serchOrder();  // 更新页面数据
                } else {
                    alert('添加失败');
                }
                setOpen(false);
            } catch (error) {
                console.error('Failed to add data:', error);
                alert('添加失败');
            }
            
    };

    const handleSetPrintQuantity = async () => {
        try {
            console.log("開始處理 expectedRows:", expectedRows);
            // 使用 Promise.all 來處理 expectedRows 中的每一行
            const updatedRows = await Promise.all(expectedRows.map(async (row) => {
                let order64;
                let piNum64;
    
                // 生成 order64 條碼
                await new Promise((resolve) => {
                    generateBarcodes(row.orderNum + row.orderSerial, (barcode) => {
                        order64 = barcode;
                        resolve();
                    });
                });
    
                // 生成 piNum64 條碼
                await new Promise((resolve) => {
                    generateBarcodes(row.piNum, (barcode) => {
                        piNum64 = barcode;
                        resolve();
                    });
                });
    
                // 返回新的 row 對象，包含新增的 order64 和 piNum64 字段
                return {
                    ...row,
                    order64,
                    piNum64,
                };
            }));
    
            // 按照 drawNum (圖號) 字段進行排序
            const sortedRows = updatedRows.sort((a, b) => {
                if (a.drawNum < b.drawNum) return -1;
                if (a.drawNum > b.drawNum) return 1;
                return 0;
            });
    
            // 根據 printQuantity 次數處理每一筆數據
            const newPrintItems = [...printItems]; // 創建 printItems 的副本
    
            sortedRows.forEach((row) => {
                const rowCopies = Array(row.printQuantity).fill(row); // 根據 printQuantity 創建 row 的副本數組
    
                rowCopies.forEach((copy) => {
                    if (newPrintItems.length === 0 || !Array.isArray(newPrintItems[newPrintItems.length - 1])) {
                        newPrintItems.push([copy]); // 在數組中新增一個子數組
                    } else if (newPrintItems[newPrintItems.length - 1].length === 6) {
                        newPrintItems.push([copy]); // 如果最後一個子數組已有6個元素，則新增一個子數組
                    } else {
                        newPrintItems[newPrintItems.length - 1].push(copy); // 否則，將 copy 添加到最後一個子數組中
                    }
                });
            });
    
            // 設置 printItem 為平鋪的 rowCopies 數組
            setPrintItem(newPrintItems.flat());
    
            // 更新 printItems
            setPrintItems(newPrintItems);
            alert('設定完成');
            console.log('Updated Print Items:', newPrintItems);
            handlePrintClickOpen();
        } catch (error) {
            console.error('Error in handleSetPrintQuantity:', error);
        }
    };
    
    const handlePrintQuantityChange = useCallback((id, value) => {
        if (!Number.isNaN(value)) {
            console.log(`Updating printQuantity for id ${id} to ${value}`); // 调试日志
            setExpectedRows((prevRows) =>
                prevRows.map((row) => {
                    if (row.id === id) {

                        const updatedRow = { ...row, printQuantity: value };
                        return updatedRow;
                    }
                    return row;
                })
            );
        }
    }, []);
    
    const handlePrint = async () => {
        try {

            await handleSetPrintQuantity();
            handlePrintClickOpen();  
            console.log("清空 printItems...");
             setPrintItems([]);
        } catch (error) {
            console.error("handlePrint 發生錯誤:", error);
            alert("列印操作失敗");
        }
    };
    
    

    const handleCheckPrint = async(trueOrfalse) => {
        if(trueOrfalse) {
            try {
                const rowsWithoutPrintQuantity = expectedRows.map(row => {
                    const { printQuantity,itemIndex, ...rest } = row; // 使用解構賦值來排除 PrintQuantity
                    return rest; // 回傳不包含 PrintQuantity 的新物件
                });
                console.log(rowsWithoutPrintQuantity);
                const response = await axios.post(
                    `${apiUrl}/Inspection/ResetReserveOrder`,
                    rowsWithoutPrintQuantity, // 主體資料
                    {
                        headers: {
                            UserName: sessionStorage.getItem('userName') // 自定義標頭
                        }
                    }
                );
                
            
                if (response.data.statusCode === 200) {
                  alert('成功');
                  
                  setRows(response.data.orders)
                  // 可以在这里添加其他成功的处理逻辑
                } else {
                  alert('失败');
                }
              } catch (error) {
                console.error('Failed to add data:', error);
                alert('失败');
              }
            setCheckPrint(false);
            setPrintItem([]);
            setPrintItems([]);
        } else {
            setCheckPrint(false);
            setPrintItem([]);
            setPrintItems([]);
        }
        
    }
    React.useEffect(() => {
        if (data && data.orders) {
            setRows(data.orders);
        }
        }, [data]);



    // React.useEffect(() => {
        // setPinumCounts(countPiNUms(printItems));
        // }, [printItems]);

    React.useEffect(() => {
         const fetchData = async () => {
            try 
            {
                const response = await axios.get(`${apiUrl}/Inspection/GetReserveOrders?UserName=${sessionStorage.getItem('userName')}`);
                if (response.data.reservationOrders != null) {
                  const ordersWithDefaults = response.data.reservationOrders.map((order,index) => ({
                    ...order,
                    expectedAmount: order.expectedAmount ?? order.orderAmount,
                    printQuantity: Number(order.printQuantity) || 2,
                    itemIndex: index + 1,
                    }));
                  setExpectedRows(ordersWithDefaults);
                } else 
                {
                  alert('数据加载失败');
                }
            } 
            catch (error) 
            {
                console.error('获取数据失败:', error);
                alert('数据加载失败');
            }
            };
        
        fetchData();
    }, []);

    useEffect(() => {
        setExpectedRows(prevRows => {
            const updatedRows = prevRows.map((row, index) => {
                // 檢查 itemIndex 是否需要更新
                if (row.itemIndex !== index + 1) {
                    return { ...row, itemIndex: index + 1 };
                }
                return row;
            });

            // 檢查是否有任何變化
            const isDifferent = updatedRows.some((row, index) => row.itemIndex !== prevRows[index].itemIndex);

            // 如果有變化，則更新狀態
            if (isDifferent) {
                return updatedRows;
            }

            // 如果沒有變化，返回原來的狀態以避免無限循環
            return prevRows;
        });
    }, [expectedRows]);


    const columns = [
    
    {
        field: '客戶',
        headerName: '客戶',
        width: 80,
        editable: false,
    },
    {
        field: '批號',
        headerName: '批號',
        width: 120,
        editable: false,
    },
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 120,
        editable: false,
    },
    {
        field: '流水號',
        headerName: '流水號',
        sortable: false,
        width: 60,
        editable: false,
    },
    {
        field: '訂購單價',
        headerName: '訂購單價',
        type:'number',
        sortable: true,
        width: 120,
        editable: false,
    },
    {
        field: '訂單數量',
        headerName: '訂單數量',
        type:'number',
        sortable: false,
        width: 100,
        editable: false,
    },
    {
        field: '未印',
        headerName: '未印',
        sortable: false,
        type:'number',
        width: 80,
        editable: false,
    },
    {
        field: '圖號',
        headerName: '圖號',
        sortable: false,
        width: 120,
        editable: false,
    },
    {
        field: '項次',
        headerName: '項次',
        sortable: false,
        width: 60,
        editable: false,
    },
    {
        field: '物品名',
        headerName: '物品名',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '承製公司',
        headerName: '承製公司',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '客戶納期',
        headerName: '客戶納期',
        sortable: false,
        width: 160,
        editable: false,
    },
    
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: '檢票OK',
        headerName: '檢票OK',
        width: 160,
        editable: true,
        renderCell: (params) => (
            <Checkbox
            checked={params.value}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => {
                const newRows = rows.map(row => {
                if (row.id === params.id) {
                    return { ...row, inspectionOK: !params.value };
                }
                return row;
                });
                setRows(newRows);
            }}
            />
        ),
    },

    ];
    const detailColumns = [
        {
            field: 'itemIndex',
            headerName: '項次',
            width: 80,
            editable: false, // 不允許編輯
            sortable: true,  // 可選：是否允許排序
            align: 'center', // 水平對齊方式
        },
    
        {
            field: 'delete',
            headerName: '刪除',
            width: 80,
            editable: false,
            renderCell: (params) => (
              <Button startIcon={<DeleteOutlinedIcon />}
                onClick={() => handleDeleteReOrder(params.row)}
              >
               刪除
              </Button>
            ),
        },
        {
            field: 'piNum',
            headerName: '批號',
            width: 100,
            editable: false,
        },
        {
            field: 'orderNum',
            headerName: '訂單號碼',
            width: 100,
            editable: false,
        },
        {
            field: 'model',
            headerName: '機種',
            width: 140,
            editable: true, // 设为可编辑
        },
        {
            field: 'drawNum',
            headerName: '圖號',
            sortable: true,
            width: 140,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'custom-header', // 自訂標題類名
            cellClassName: 'custom-cell',     // 自訂儲存格類名
        },
        {
            field: 'itemName',
            headerName: '品名',
            sortable: true,
            width: 120,
            editable: false,
        },
        
        {
            field: 'orderAmount',
            headerName: '訂單量',
            sortable: false,
            width: 70,
            editable: false,
        },
        {
            field: 'expectedAmount',
            headerName: '預交貨量',
            sortable: false,
            width: 90,
            editable: true, // 设置为不可编辑
            // 移除 renderCell
        },
        {
            field: 'printQuantity',
            headerName: '列印数量',
            width: 100,
            editable: true,
            type: 'number',
            renderCell: (params) => 
            (
                <PrintQuantityCell
                    id={params.row.id}
                    value={params.value }
                    handlePrintQuantityChange={handlePrintQuantityChange}
                />
            )
        },
        {
            field: 'expectedTradeDate',
            headerName: '交貨日期',
            sortable: false,
            width: 100,
            editable: false,
        },
        
        { field: 'id', headerName: 'ID', width: 90 }
        
    ];

    const processRowUpdate = (newRow, oldRow) => {
        // 验证 model 字段是否为空
        

        // 更新 expectedRows 状态
        setExpectedRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );

        return newRow;
    };

    return (
        <Box m={2}>
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel id="demo-row-radio-buttons-group-label">過濾條件</FormLabel>                
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e)=>setFilter(e.target.value)}
                        >
                            <FormControlLabel value="批號" control={<Radio />} 
                            label="批號" />
                            <FormControlLabel value="圖號" control={<Radio />} 
                            label="圖號" />
                            <FormControlLabel value="訂單號碼" control={<Radio />} 
                            label="訂單號碼" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue=""
                        size="small"
                        onChange={(e) =>setFilterValue(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={serchOrder}>
                        搜尋
                    </Button>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSelectAll}>
                        整批選取
                    </Button>
                </Grid>
                
            </Grid>
            <Box sx={{ height: 450, width: '90%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 100,
                    },
                },
                }}
                onRowDoubleClick={(params,event,details)=>handleDialogOpen(params,event,details)}
                pageSizeOptions={[50,100]}
                disableRowSelectionOnClick
            />
            <Typography variant="h3" display="inline">預交貨訂單</Typography>
            <Grid container spacing={1} alignItems="center" style={{ display: 'inline-flex', marginLeft: '16px' }}>
                <Grid item>
                        <TextField id="outlined-basic" label="請輸入訂單號碼或條碼" variant="outlined"
                        value={barCode} onChange={(e) => setBarCode(e.target.value)} />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => getoldorder()}
                    >
                        查詢
                    </Button>
                </Grid>
            </Grid>

            <Tooltip TransitionComponent={Zoom} title="Save">
                <Button onClick={()=>SaveAll(expectedRows)}>清除列印資料</Button>
                <Button onClick={handlePrint}>列印</Button>
                <Button onClick={() =>handleCheckPrint(true)}>儲存資料</Button>
            </Tooltip>
           
            <DataGrid
                rows={expectedRows}
                columns={detailColumns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 100,
                    },
                },
                }}
                       
                        pageSizeOptions={[100,400]}
                        disableRowSelectionOnClick
                        processRowUpdate={processRowUpdate} // 使用 processRowUpdate
                        experimentalFeatures={{ newEditingApi: true }} // 启用新编辑 API
                        onProcessRowUpdateError={(error) => {
                        console.error(error);
                        }}
            />
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <DialogTitle>預交貨訂單數量確認</DialogTitle>
                <DialogContent>
                <DialogContentText>
                訂單號碼 {orderNum} 預交貨數量 (最大: {sendRow?.未印})
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="數量"
                    type="number" // 确保类型为 number
                    fullWidth
                    variant="standard"
                    defaultValue={maxAmount}
                    onChange={(e) => setMaxAmount(Number(e.target.value))}
                    inputProps={{ min: 0, max: sendRow?.未印 }}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setOpen(false)}>取消</Button>
                <Button onClick={sendOrder}>送出</Button>
                </DialogActions>
            </Dialog> 
            <Dialog
                fullScreen
                open={print}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    列印預覽
                    </Typography>
                </Toolbar>             
                </AppBar>         
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument data={printItem} datas={printItems} />
                </PDFViewer>
            </Dialog> 



                <Dialog
                    open={checkPrint}
                    onClose={() =>handleCheckPrint(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"請問您剛剛是否有列印?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        若有列印 請選擇是，會將該筆預交貨訂單刪除 並更改資料庫<br/>
                        若無列印 請選擇否，資料庫保持原樣
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() =>handleCheckPrint(true)}>已列印</Button>
                    <Button onClick={() =>handleCheckPrint(false)} autoFocus>
                        未列印
                    </Button>
                    </DialogActions>
                </Dialog>  
            </Box>
        </Box>
    );
}


Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont, // 使用您的实际路径
  });
Font.register({
family: 'TaipeiSansTCBeta-Light',
src: chLight, // 使用您的实际路径
});
  
const styles = StyleSheet.create({
    page: {
    flexDirection: 'row', // 横向布局
    flexWrap: 'wrap', // 允许内容换行
    padding: 10,
      backgroundColor: 'white',
    },
    gridContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    gridTitle: {
        flexGrow: 0,
        fontFamily: 'TaipeiSansTCBeta-Bold',
        marginLeft:'30%',
        fontSize: 14,
        marginBottom: 10
    },
    gridItem1: {
      flexGrow: 1,
      fontSize: 12,
      padding: 0,
      marginBottom: 10,
      fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    gridItem2: {
        flexGrow: 1,
        fontSize: 12,
        padding: 0,
        marginBottom: 10,
        fontFamily: 'TaipeiSansTCBeta-Light',
    },
    gridImg: {
        flexGrow: 1,
        width: '50%', 
        height: '50%', 
        marginTop:5,
        marginBottom:5
    },
    section: {
        width: '50%', // 占据50%宽度，一行两个
        height: '33.33%', // 占据33.33%高度，一列三个，共6个占满一页
        padding: 10,
        // flexGrow: 1,   
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
  });
  
  
  
  const MyDocument = ({datas}) => {
    console.log(datas); 
    if (!datas || datas.length === 0) {
        return <Text>没有数据可显示</Text>;  // 如果数据为空，显示替代内容
      }

    const today = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        // getMonth() 返回的月份是从 0 开始的，所以需要加 1
        const month = String(date.getMonth() + 1).padStart(2, '0');
        // getDate() 用于获取月份中的日
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };
    

    return(
    <>  
            <Document>       
                {datas.map((details, pageIndex) => (
                    <Page size="A4" style={styles.page} key={pageIndex}>
                        {details.map((data, index) => (
                        <View style={styles.section} key={index}>
                        <View style={styles.gridTitle}>
                            <Text>台灣日立公司交貨現品票</Text>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>廠商名稱:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>華辰企業股份公司</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>交貨日期:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{formatDate(today)}</Text>
                            </View>
                        </View> 
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>料件:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.drawNum}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>物品名:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.itemName}</Text>
                            </View>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>訂單數量:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.orderAmount}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>交貨數量:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.expectedAmount}</Text>
                            </View>
                        </View>
                        {/* <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>客戶名稱:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.client}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>客戶訂單:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.client}</Text>
                            </View>
                        </View> */}
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>英文品名:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>BASE</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>倉庫:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>36525B</Text>
                            </View>
                        </View>
                        {/* <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>規格:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>2BG654814</Text>
                            </View>
                        </View> */}
                        <View style={styles.gridContainer}>
                            {/* <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>1C1</Text>
                            </View> */}
                            <View style={styles.gridItem1}>
                                <Text>收貨中心:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>Q1</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>現場位置:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>1C1</Text>
                            </View>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>訂單號碼:</Text>
                            </View>
                            <View style={styles.gridImg}>
                                <Image src={data.order64} />
                                <Text style={styles.barcodeText}>{`${data.orderNum}${data.orderSerial}`}</Text>    
                            </View>
                        </View>


                        <View style={styles.gridContainer}>
                            {/* <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>1C1</Text>
                            </View> */}
                            <View style={styles.gridItem1}>
                                <Text>收貨中心:</Text>
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>使用工單:</Text>
                            </View>
                            <View style={styles.gridImg}>                            
                                <Image src={data.piNum64} />
                                <Text style={styles.barcodeText}>{data.piNum}</Text>      
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>1C1</Text>
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>使用專案:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.model}</Text>
                            </View>
                        </View>
                    </View>
                    ))}
                </Page>
                ))}            
            </Document>
        
  </>
  )};
